<script>
//import Multiselect from "vue-multiselect";
import {required,} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Select2 from 'v-select2-component';
import "vue-multiselect/dist/vue-multiselect.min.css";
import TopTenService from "@/services/TopTenService";
const itemService = new TopTenService();


import ArregloService from "@/services/ArregloService";
const relationService = new ArregloService();



import InfoModel from "@/classes/topten";
const infoModel = new InfoModel();

import HelperJS from "@/functions/general";
const helper = new HelperJS();

/**
 * Add-product component
 */
const nameSeccion = 'Los más vendidos';
const nameRoute = 'Topten';
//const namePlural = nameSeccion+'s';
const routeParentName = 'topten';

export default {
  page: {
    title: nameSeccion,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Select2,
  },
  data() {
    return {
      title: nameSeccion,
      value: '',
      actionCreate: true,
      info_page:{
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
      },
      relationArray: [],
      tipos: [],
      objectData:{},
      items: [
        {
          text: null,
        },
        {
          text: null,
          active: true,
        },
      ],
      submitted: false,

      
    };
  },
  validations: {
      objectData: {
        arreglo_id: {
          required,
        }
      },
    },
  middleware: "authentication",
  
  created(){

    this.initPage();
  },
  
  methods: {
    async initPage(){
        
        await this.aditionalServices();
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        let text_page = 'Agregar '
        
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        if(this.$route.name===nameRoute+'Edit'){          
          this.actionCreate = false;
          text_page = 'Editar';

          itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));
          })
        }
        this.items[0].text= nameSeccion
        this.items[1].text= text_page+' '+nameSeccion;
      },
      async aditionalServices(){
        let response = await relationService.getList();
        response =  response.data.object_data;
        
        response.map(item=> item.text = item.name);

        //response.map(item=> item.status = false);
        this.relationArray = response
      },
      async updateForm(){
      
          let formData = JSON.parse(JSON.stringify(this.objectData));
          if(this.actionCreate){
            delete formData.id;
            await itemService.create(formData)
          }else{
            await itemService.update(formData)
          }
          this.successForm(this.info_page.routeBack);
      
      },

      successForm(route){
        Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
        this.$router.push({
              path: route,
            });
        });
    },
    cancelForm(){
      this.$router.push({
        path:'/'+routeParentName,
      });
    },
      
    validateForm(){
        this.submitted = true;
        if(this.$v.$error===false &&  this.$v.$invalid===false){
          this.updateForm();
        }
        
      this.$v.$touch();
    },
    slugify(stringValue){

      return helper.convertToSlug(stringValue);
    },
    routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información general</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form>
                 
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label for="name">Nombre</label>
                        <Select2  
                         :class="{
                            'is-invalid': submitted && $v.objectData.arreglo_id.$error,
                          }"
                        class="form-control form-custom" v-model="objectData.arreglo_id" :options="relationArray"
                         :settings="{ settingOption: value, settingOption: value }"
                         />              
                    
                        <div
                          v-if="submitted && $v.objectData.arreglo_id.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.arreglo_id.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                
                  </div>
               
                </form>
              </div>
            </b-collapse>
          </div>

         

        
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger"  @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <a class="btn btn-success ms-1"  @click.prevent="validateForm()">
          <i class="uil uil-file-alt me-1" ></i> Guardar cambios
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
